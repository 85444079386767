import fp from "lodash/fp"
import { DateTime } from "luxon"

import { arrayRange, monthsRange } from "helpers/utils/common"
import { ONE_DAY_MILLISECONDS, TEN_MINUTES } from "helpers/utils/constants"

import type {
  CellViewType,
  CustomProperties,
  IRecordWTimestamp,
} from "types/dashboard.types"
import type { ILimits } from "types/linePlot.types"

import { toSentenceCase } from "helpers/utils/translations"

/**
 * Get the ranges for the x and y values
 *
 * @param {CellViewType} view - The view definition of the plot cell
 * @param {IRecordWTimestamp[]} data - The data to be plotted
 * @returns {number[][]} - the bounds for x (min, max) and y (min, max)
 */
export const getXYPlotBounds = (
  view: CellViewType,
  data: IRecordWTimestamp[],
): number[][] => {
  let highestDataValue = Number.NEGATIVE_INFINITY
  let lowestDataValue = Number.POSITIVE_INFINITY
  const properties: CustomProperties = view?.properties || { type: "" }

  const xKey = (properties?.xColumn || "_time") as string
  const yKey = (properties?.yColumn || "_value") as string
  const [xBoundMin, xBoundMax] = properties.axes?.x?.bounds || []
  const [yBoundMin, yBoundMax] = properties.axes?.y?.bounds || []
  let [xMin, xMax] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]

  for (const obj of data) {
    const yValue = obj[yKey] as number
    if (yValue < lowestDataValue) {
      lowestDataValue = yValue
    }
    if (yValue > highestDataValue) {
      highestDataValue = yValue
    }
    if (xKey === "_time") {
      const start = obj._start as number | string | undefined
      if (xMin === Number.POSITIVE_INFINITY && start) {
        xMin = new Date(start).getTime()
      } else if (start === undefined) {
        const xValue = obj[xKey]
        if (xValue !== undefined) {
          const time = new Date(xValue).getTime()
          if (time < xMin) {
            xMin = time
          }
        }
      }
    } else {
      const xValue = obj[xKey] as number
      if (xValue < xMin) {
        xMin = xValue
      }
    }
    if (xKey === "_time") {
      const stop = obj._stop as number | string | undefined
      if (xMax === Number.NEGATIVE_INFINITY && stop) {
        xMax = new Date(stop).getTime()
      } else if (stop === undefined) {
        const xValue = obj[xKey]
        if (xValue !== undefined) {
          const time = new Date(xValue).getTime()
          if (time > xMax) {
            xMax = time
          }
        }
      }
    } else {
      const xValue = obj[xKey] as number
      if (xValue > xMax) {
        xMax = xValue
      }
    }
  }

  xMin =
    xBoundMin && Number.parseFloat(xBoundMin) < xMin ? Number.parseFloat(xBoundMin) : xMin
  xMax =
    xBoundMax && Number.parseFloat(xBoundMax) > xMax ? Number.parseFloat(xBoundMax) : xMax

  let yMin =
    yBoundMin && Number.parseFloat(yBoundMin) <= lowestDataValue
      ? Number.parseFloat(yBoundMin)
      : lowestDataValue

  let yMax =
    yBoundMax && Number.parseFloat(yBoundMax) >= highestDataValue
      ? Number.parseFloat(yBoundMax)
      : highestDataValue

  const yRange = yMax - yMin
  yMin -= 0.05 * yRange
  yMax += 0.05 * yRange

  return [
    [xMin, xMax],
    [yMin, yMax],
  ]
}

/**
 * RechartsDataType - a single item for recharts
 */
interface RechartsDataType {
  name: any
  data: object[]
}

/**
 * Format the data for Recharts graphs.
 * This generates a new array of objects grouped by the key
 *
 * @param {Record<string, any>[]} array
 * @param {string} key to group by
 * @returns {RechartsDataType[]} a new array of objects formatted
 */
export const groupByKey = (
  array: Record<string, any>[],
  key: string,
): RechartsDataType[] => {
  const byKey = fp.groupBy(fp.get(key), array)
  return fp.map(([key, value]) => ({ name: key, data: value }), fp.toPairs(byKey))
}

/**
 * Function that generates an array of ticks between two limits
 *
 * @param {ILimits} limits
 * @returns {number[]} array of dates formatted in milliseconds
 */
export const ticksGenerator = (limits: ILimits): number[] => {
  const interval = 5
  let left = limits?.left ?? 0
  const right = limits?.right ?? left
  let range = right - left
  let step = range / interval

  if (range < TEN_MINUTES * interval) {
    /**
     * TODO: we could consider that when the range is less than "TEN_MINUTES * interval" could be worked with "FIVE_MINUTES" multiples. Interesting to discuss
     */
    return arrayRange(left, right, step)
  }
  // If left is not multiple of 10 minutes, set as left the previous number multiple of 10 minutes
  if (left % TEN_MINUTES > 0) {
    left = TEN_MINUTES * (Math.trunc(left / TEN_MINUTES) + 1)
  }

  range = right - left
  step = Math.trunc(range / interval)

  if (step % TEN_MINUTES > 0) {
    step = TEN_MINUTES * (Math.trunc(step / TEN_MINUTES) + 1)
  }
  // If the range is >= 90 days
  if (range >= ONE_DAY_MILLISECONDS * 30 * 3) {
    return monthsRange(left, right)
  }
  return arrayRange(left, right, step)
}

/**
 * Function that generates an array of ticks between two limits
 *
 * @param {ILimits} limits
 * @returns {number[]} array of dates formatted in milliseconds
 */
export const ticksYAxisGenerator = (limits: ILimits): number[] => {
  const interval = 4
  const bottom = limits?.bottom ?? 0
  const top = limits?.top ?? bottom

  const range = top - bottom
  const step = range / interval

  return arrayRange(bottom, top, step)
}

/**
 * Function that returns the tick formatted
 * @param {number} unixTimestamp
 * @param {number} range difference between right and left limit
 * @param {string} locale locale configuration
 * @returns {string} return the tick formatted
 */
export const tickFormatter = (
  unixTimestamp: number,
  range: number,
  locale: string,
): string => {
  const interval = 5

  // If the range is >= 90 days
  if (range >= ONE_DAY_MILLISECONDS * 30 * 3) {
    const date = DateTime.fromMillis(unixTimestamp)
      .setLocale(locale)
      .toFormat("LLL yyyy")
      .toString()
    return toSentenceCase(date)
  }
  if (range >= ONE_DAY_MILLISECONDS * 10) {
    return DateTime.fromMillis(unixTimestamp)
      .setLocale(locale)
      .toFormat("yyyy-MM-dd")
      .toString()
      .toUpperCase()
  }
  if (range < TEN_MINUTES * interval) {
    return DateTime.fromMillis(unixTimestamp)
      .setLocale(locale)
      .toFormat("yyyy-MM-dd HH:mm:ss")
      .toString()
      .toUpperCase()
  }
  return DateTime.fromMillis(unixTimestamp).toFormat("yyyy-MM-dd HH:mm")
}
