import type { TFunction } from "i18next"
import fp from "lodash/fp"

import type { DeviceTelemetryType, TelemetryType } from "../../types/telemetries.types"

export const getTelemetryTranslation = (name: string, t: TFunction) => {
  const formattedFieldName = name.replaceAll(".", "_")
  const defaultValue = fp.capitalize(name.replaceAll(".", " "))
  const lastIndexOfUnderscore = formattedFieldName.lastIndexOf("_")
  const lastChunk = name.slice(lastIndexOfUnderscore + 1)

  if (/^-?\d+$/.test(lastChunk)) {
    const firstChunk = formattedFieldName.slice(0, lastIndexOfUnderscore)
    return t(`telemetries.${firstChunk}`, {
      defaultValue,
      value: lastChunk,
    })
  }
  return t(`telemetries.${formattedFieldName}`, { defaultValue })
}

export const formatSelectTelemetries = (
  telemetries: TelemetryType[] | undefined,
  t: TFunction,
): { label: string; value: string }[] =>
  fp.flow(
    fp.map(({ _field }) => {
      return {
        label: getTelemetryTranslation(_field, t),
        value: String(_field),
      }
    }),
    fp.sortBy(({ label }) => label.toLowerCase()),
  )(telemetries || [])

export const getSignalTransportType = (value: number): string => {
  if ((256 & value) !== 0) {
    return "5g"
  }
  if ((128 & value) !== 0) {
    return "4g"
  }
  if ((64 & value) !== 0) {
    return "3g"
  }
  if ((32 & value) !== 0) {
    return "2g"
  }
  if ((16 & value) !== 0) {
    return "wifi"
  }
  if ((8 & value) !== 0) {
    return "lora"
  }
  if ((2 & value) !== 0) {
    return "sms"
  }
  return "mobile"
}

export const getSignalLevelLegend = (name?: string, transport?: number): string => {
  let legend = ""
  if (name) {
    legend += name
  }
  if (transport) {
    legend += ` (${getSignalTransportType(transport)})`
  }
  return legend
}

export const formatSignalStatusData = (data: DeviceTelemetryType[]) => {
  const netSignal = data?.find((d: DeviceTelemetryType) => d._field === "net.signal")
  const netName = data?.find((d: DeviceTelemetryType) => d._field === "net.name")
  const netTransport = data?.find(
    (d: DeviceTelemetryType) => d._field === "net.transport",
  )

  return [
    {
      ...netSignal,
      legend: getSignalLevelLegend(
        netName?._value as string | undefined,
        netTransport?._value as number | undefined,
      ),
    },
  ]
}

export const formatBatteryStatusData = (data: DeviceTelemetryType[]) => {
  const batteryValue = data?.find(
    (d: DeviceTelemetryType) => d._field === "power.battery.level",
  )
  const batteryStatus = data?.find(
    (d: DeviceTelemetryType) => d._field === "power.battery.status",
  )

  const getLegend = (value: number): string => {
    if ((value & 2) !== 0) {
      return "Charging"
    }
    return "Discharging"
  }

  const legend =
    batteryStatus?._value !== undefined
      ? getLegend(batteryStatus._value as number)
      : undefined

  return [
    {
      ...batteryValue,
      legend,
    },
  ]
}
