import { DateTime } from "luxon"
import fp from "lodash/fp"

export function filterObject(
  obj: { [key: string]: any },
  callback: (key: string, value: any) => boolean,
) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => callback(key, value)),
  )
}

export const arrayRange = (start: number, stop: number, step: number) => {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step,
  )
}

/**
 * Get 1st day of each month in an interval
 * @param {number} start datetime in millis
 * @param {number} stop datetime in millis
 *
 * @returns {number[]} - the first of month datetimes in millis in the interval
 */
export const monthsRange = (start: number, stop: number): number[] => {
  const first = DateTime.fromMillis(start)
  const last = DateTime.fromMillis(stop)
  const result: number[] = []
  for (let current = first; current <= last; current = current.plus({ month: 1 })) {
    const startOfMonth = current.set({ day: 1 })
    if (startOfMonth > first && startOfMonth <= last) {
      result.push(startOfMonth.toMillis())
    }
  }
  return result
}

export const getBrand = (href: string): string => {
  const url = new URL(href)
  switch (url.hostname) {
    case "dashboard.sentrisense.com": // fallsthrough
    case "board.sentrisense.com":
      return "sentrisense"
    case "beta.lessindustries.com": // fallsthrough
    case "beta-board.lessindustries.com": // fallsthrough
    case "dashboard.beta.sentrisense.com": // fallsthrough
    case "board.beta.sentrisense.com":
      return "beta"
    case "alpha.lessindustries.com": // fallsthrough
    case "alpha-board.lessindustries.com": // fallsthrough
    case "dashboard.alpha.sentrisense.com": // fallsthrough
    case "board.alpha.sentrisense.com":
      return "alpha"
    case "localhost":
      return "local"
    default:
      return "less"
  }
}

/**
 * Convert a decimal degree to sign, degrees, minutes, seconds
 *
 * @param {number} degrees
 *
 * @returns {number[4]} sign, degrees, minutes, seconds
 */
const decimalToDMS = (degrees: number): number[] => {
  const sign = degrees >= 0 ? 1 : -1
  const aux = Math.abs(degrees)
  const d = aux | 0
  const m = ((aux % 1) * 60) | 0
  const s = (((aux * 60) % 1) * 60) | 0
  return [sign, d, m, s]
}

/**
 * Stringify a dms value
 *
 * @param {number[4]} dms, signed degrees, minutes, seconds
 *
 * @returns {string} d°m's"
 */
const DMStoString = (dms: number[]): string => {
  const [sign, d, m, s] = dms
  const minutes = m.toString().padStart(2, "0")
  const seconds = s.toString().padStart(2, "0")
  return `${sign >= 0 ? "" : "-"}${d}°${minutes}'${seconds}"`
}

/**
 * Format decimal degrees as a degrees, minutes, seconds string
 *
 * @param {number} degrees
 *
 * @returns {string} d°m's"
 */
export const degreesToString = (degrees: number): string => {
  const dms = decimalToDMS(degrees)
  return DMStoString(dms)
}

/**
 * Floored integer division
 *
 * together with mod they implement Knuth's modulo operation, see: https://en.wikipedia.org/wiki/Modulo
 *
 * @param {number} dividend
 * @param {number} divisor
 * @returns {number}
 */
export const quotient = (dividend: number, divisor: number): number => {
  return Math.floor(dividend / divisor)
}

/**
 * Take two numbers and returns a number between [0, max), by obtaining the remainder between the two
 *
 * together with quotient they implement Knuth's modulo operation, see: https://en.wikipedia.org/wiki/Modulo
 *
 * @param {number} num
 * @param {number} max
 * @returns {number}
 */
export const mod = (num: number, max: number): number => {
  return ((num % max) + max) % max
}

// Part of the next code was taken from https://stackoverflow.com/a/8831937/2263208
/**
 * Take a string an obtain it's hashCode (well known java hash function for strings)
 *
 * @param {string} str
 * @returns {number} an integer value
 */
export const strHash = (str: string): number => {
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const getNumberFromZeroToMaxFromStr = (str: string, max: number): number => {
  const hash = strHash(str)
  return mod(hash, max)
}

/**
 * Convert polar to cartesian coordinates
 *
 * @param {number} radius
 * @param {number} ang in degrees
 * @returns {[number, number]}
 */
export const polarToCartesian = (radius: number, ang: number): [number, number] => {
  const coordX = radius * Math.cos(Math.PI - (ang * Math.PI) / 180.0)
  const coordY = radius * Math.sin(Math.PI - (ang * Math.PI) / 180.0)

  return [coordX, coordY]
}

// Paginate a list with a default limit of 10
export const paginateList = <T>(page: number, list: T[], limit = 10): T[] => {
  const firstIndex = page * limit
  const lastIndex = (page + 1) * limit
  return list.slice(firstIndex, lastIndex)
}

/**
 * Parse a value from string to float
 * @param {string | number} value
 * @returns {number} value
 */
export const parseToFloat = (value: string | number): number => {
  return typeof value === "string" ? parseFloat(value) : value
}

/**
 * Compare, as required by sort methods
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
 *
 * @param a: first element
 * @param b: another element
 * @returns -1, 0 or 1, corresponding to a < b, a == b, a > b
 */
export const compare = <T>(a: T, b: T): number => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 * Checks if value is valid, checking if it is not null, undefined or an empty string.
 * @param {any} value
 * @returns {boolean}
 */
export const isValidValue = (value: any) => {
  return !fp.isNil(value) && value !== ""
}
