import { t } from "i18next"
import fp from "lodash/fp"

import type {
  CellViewType,
  CustomProperties,
  IInfluxCellView,
  IPlotView,
} from "types/dashboard.types"

export const translateBoolean = (value: boolean) => {
  return value ? t("generic.YES") : t("generic.NO")
}

export const getTranslationKey = (text: string) => {
  return text
    .replace(/[^A-Za-z]+/g, " ")
    .trim()
    .replace(/\s+/g, "_")
    .toUpperCase()
}

const getPlotUnit = (view: CellViewType) => {
  switch (view.properties.type) {
    case "xy":
      return view.properties.axes?.y.suffix
    case "gauge":
      return view.properties.suffix
    case "single-stat":
      return view.properties.suffix
    default:
      return ""
  }
}

export const getPlotName = (view: CellViewType, withUnit: boolean) => {
  let nameKey
  const unit = withUnit ? `[${getPlotUnit(view)}]` : ""

  const isPlotView = (view: IPlotView | IInfluxCellView): view is IPlotView =>
    "nameKey" in view

  if (isPlotView(view)) {
    nameKey = view.nameKey
  } else {
    const dividerIndex = view.name.lastIndexOf("[")
    nameKey = dividerIndex > -1 ? view.name.slice(0, dividerIndex) : view.name
  }

  nameKey = getTranslationKey(nameKey)

  const tooltip = (view.properties as CustomProperties)?.tooltip

  return { nameKey, unit, tooltip }
}

export const addEndPeriodIfMissing = (text: string) => {
  return text.trimEnd().slice(-1) === "." ? text : text + "."
}

type ToSentenceCase = (text: string) => string
export const toSentenceCase: ToSentenceCase = fp.capitalize
